<template>
    <div class="section-layout-wrapper" v-if="data && reArrangeElements && reArrangeElements.length > 0">
        <slot :name="handleTemplateName(section.settings.template, sectionIndex)"
            v-for="(section, sectionIndex ) in  data.sectionsExpanded " :key="sectionIndex" :data="section"
            :componentIndex="sectionIndex">
            <section v-if="section" class="section"
                :class="[handleStringFormat(section.settings.class), handleStringFormat(section.settings.template)]"
                :data-section-index="sectionIndex"
                :data-template-name="handleTemplateName(section.settings.template, sectionIndex)">
                <span class=" anchor" :id="section.settings.anchorPoint"></span>
                <div class="container">

                    <slot :name="'header-' + handleTemplateName(section.settings.template, sectionIndex)"></slot>

                    <!-- Collection content i.e. TemplateContent -->
                    <div class="column-wrapper" v-if="section.contentCollection && section.contentCollection.length > 0">
                        <div class="collection" :class="['column ' + getContentType(section.contentCollection)]">
                            <div class="items-wrapper">
                                <ul>

                                    <li v-for="( element, elementIndex  ) in  section.contentCollection" :key="elementIndex"
                                        :class="getContentType(section.contentCollection)">
                                        <SingleTemplateContent :data="element.templateContent"
                                            :contentType="element.contentName.toString().toLowerCase()" />
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <!-- Std -->
                    <div class="column-wrapper"
                        v-else-if="section.elements && section.elements.length > 0 && section.contentCollection && section.contentCollection.length == 0"
                        :class="['column-count-' + section.elements.length, buildContainsClasslist(section.elements)]">
                        <div :class="['column ' + element.entityType.toLowerCase(), addComponentNameAsClass(element)]"
                            v-for="(   element, elementIndex  ) in section.elements" :key="elementIndex">

                            <PlayList
                                v-if="element.entityType === 'Playlist' && checkElemenDeep(element.playList.relatedContents).includes('VideoContent')"
                                :playlistExpanded="element.playList" />

                            <ContentPlayList
                                v-if="element.entityType === 'Playlist' && checkElemenDeep(element.playList.relatedContents).includes('TemplateContent')"
                                :data="element.playList" :contentType="getContentType(element.playList.relatedContents)" />

                            <!-- Component -->
                            <ContentColumn
                                v-if="element.entityType === 'TemplateContent' && element.contentType === 'Component'"
                                :data="element" />

                            <!-- Price plan -->
                            <SubscriptionOverviewItem v-if="element.entityType == 'PricePlan'" :data="element.pricePlan" />
                        </div>
                    </div>

                    <slot :name="'footer-' + handleTemplateName(section.settings.template, sectionIndex)"></slot>

                </div>
            </section>
        </slot>
    </div>
</template>

<script>
//import { fetchCmsMedia } from "@streampac.io/chef_sp_1";

import { defineAsyncComponent } from "vue";

const PlayList = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Video/PlayList"));
const ContentColumn = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/ContentColumn"));
const ContentPlayList = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/ContentPlayList"));
const SubscriptionOverviewItem = defineAsyncComponent(() => import("@/components/SubscriptionOverviewItem"));

const SingleTemplateContent = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/SingleTemplateContent"));

export default {
    data() {
        return {
            addedClassesThroughTemplate: [],
            contentTypeArray: [],
        };
    },
    computed: {
        reArrangeElements: function () {
            //const that = this;

            return this.data.sectionsExpanded.filter(function (section) {

                if (section.elements && section.elements.length > 0) {
                    let elementConversionArray = [];

                    section.elements.forEach((element) => {
                        if (element.entityType === 'TemplateContent' && element.contentType === 'Page') {

                            elementConversionArray.push(element);

                        }
                    });

                    return section.contentCollection = elementConversionArray;

                }
            });

        },
    },
    components: {
        PlayList,
        ContentColumn,
        ContentPlayList,
        SubscriptionOverviewItem,
        SingleTemplateContent
    },
    mounted() {
        if (this.data) {
            //console.log(this.data);
            // this.data.sectionsExpanded.forEach((section, index) => {
            //     console.log(section);
            //     this.addedClassesThroughTemplate.push(index);
            // });
        }
        //console.log(this.reArrangeElements);
    },
    props: ['data', 'addSectionClass'],
    inject: ["isAuthenticated"],
    methods: {
        groupByList(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        groupBy(array, key) {
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] =
                    result[currentValue[key]] || []).push(currentValue);
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
        },
        parseHtml(passedHtml) {
            return passedHtml.replaceAll(
                'rel="noopener noreferrer" target="_blank"',
                ""
            );
        },
        checkElemenDeep(elem) {
            if (elem) {
                const pluck = (array, key) => {
                    return array.map((o) => o[key]);
                };

                const unique = (array) => {
                    return array.filter(function (el, index, arr) {
                        return index == arr.indexOf(el);
                    });
                };

                const pluckFields = pluck(elem, "entityType");
                const uniqueVals = unique(pluckFields);

                return uniqueVals;
            } else {
                return [];
            }
        },
        getContentType(elem) {
            const pluck = (array, key) => {
                return array.map((o) => o[key]);
            };

            const unique = (array) => {
                return array.filter(function (el, index, arr) {
                    return index == arr.indexOf(el);
                });
            };

            const contentName = pluck(elem, "contentName");
            const contentType = pluck(elem, "contentType");
            const entityType = pluck(elem, "entityType");

            const uniqueContentNames = unique(contentName);
            const uniqueContentTypes = unique(contentType);
            const uniqueEntityTypes = unique(entityType);

            if (uniqueContentTypes && uniqueContentTypes.includes('Component') && uniqueEntityTypes && uniqueEntityTypes.includes('TemplateContent')) {
                return 'component';
            } else {
                return uniqueContentNames.toString().toLowerCase();
            }
        },
        addComponentNameAsClass(elem) {
            if (elem.entityType === 'Playlist') {
                if (elem.playList) {
                    return this.transformContentType(this.getContentType(elem.playList.relatedContents));
                }
            } else if (elem.entityType === 'TemplateContent' && elem.contentType !== 'Component') {
                return elem.contentName.toString().toLowerCase();

            } else {
                return '';
            }
        },
        transformContentType(valType) {

            let valString = valType;

            let replaceChars = valString.replaceAll(/[^A-Z0-9]/ig, "");
            let returnString = replaceChars.toLowerCase();

            return returnString;

        },
        handleStringFormat(stringValue) {
            if (stringValue && stringValue !== '') {
                return stringValue.replaceAll(',', ' ')
            }

            return '';
        },
        handleTemplateName(sectionTemplate, sectionIndex) {
            if (sectionTemplate && sectionTemplate !== '') {
                return 'section-' + this.handleStringFormat(sectionTemplate);
            } else if (sectionIndex && sectionIndex !== '') {
                return 'section-' + sectionIndex;
            }
        },
        buildContainsClasslist(elements) {
            let elementTypesList = [];

            const unique = (array) => {
                return array.filter(function (el, index, arr) {
                    return index == arr.indexOf(el);
                });
            };

            if (elements && elements.length > 0) {

                elements.forEach((element) => {

                    if (element && element.entityType && element.entityType !== '') {
                        elementTypesList.push(element.entityType.toString().toLowerCase());
                    }
                });

                const uniqueVals = unique(elementTypesList);

                return 'contains-' + uniqueVals.join("-");

            }
        }
    }

};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
// @import styles
@import "./index-unscoped.scss";
</style>
